// @flow
export const STORAGE_KEY = 'driver_oauth';

/**
 * DEPRECATED: Должен быть удалён после миграции на FB.
 */
export class OAuth {
    constructor() {
        window.localStorage.removeItem(STORAGE_KEY);
    }
}
