// @flow
import React from 'react';

import type { ComponentType, Node, AbstractComponent } from 'react';

type ConusmerType<C> = ComponentType<{
    children: C => ?Node
}>;

// TODO: typings
export default function withContext<C: *>(Context: {
    Consumer: ConusmerType<C>
}) {
    return <OwnProps: {}>(
        Component: AbstractComponent<OwnProps & C>
    ): AbstractComponent<OwnProps> => {
        class WithContext extends React.PureComponent<OwnProps> {
            render() {
                return (
                    <Context.Consumer>
                        {(context: C) => (
                            <Component {...context} {...this.props} />
                        )}
                    </Context.Consumer>
                );
            }
        }

        return WithContext;
    };
}
