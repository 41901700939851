// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import api from '../api';
import { rootReducer } from '../reducers';
import { OAuth } from '../api/utils/OAuth';

import type { IAuth } from '../api/utils/Auth';
import type { Store } from '../types/store';
import type { RootState } from '../reducers';
import type { Action } from '../actions';

const getStore = (auth: IAuth): Store<RootState, Action> => {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const middlewares = [
        thunk.withExtraArgument({ api, oauth: new OAuth(), auth })
    ];

    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middlewares))
    );
};

export default getStore;
