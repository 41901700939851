// @flow

export function objToCgi(params: Object) {
    function getKeyValString(key, val) {
        return `${key}=${encodeURIComponent(val)}`;
    }
    return Object.keys(params).reduce((acc, key) => {
        if (!acc) {
            return getKeyValString(key, params[key]);
        }

        return `${acc}&${getKeyValString(key, params[key])}`;
    }, '');
}
