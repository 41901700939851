// @flow
import type { Reducer } from '../types/reducer';
import type { AuthAction } from '../actions/auth';
import type { AuthorisationV2 } from '../api/backend-types.js';

export type AuthError =
    | { type: 'network' }
    | { type: 'validation', message: string, entity: 'login' | 'password' }
    | { type: 'user', message: string };

export type UserState = $Diff<AuthorisationV2, { token: string }>;

export type AuthState = {
    +authenticated: boolean,
    +user: ?UserState,
    +error: ?AuthError,
    +loading: boolean
};

const initialState: AuthState = {
    authenticated: false,
    user: null,
    error: null,
    loading: false
};

export const auth: Reducer<AuthState, AuthAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case 'AUTHENTICATION': {
            return {
                ...state,
                loading: true
            };
        }
        case 'AUTHENTICATION_SUCCESS': {
            return {
                ...state,
                user: action.data,
                authenticated: true,
                error: null,
                loading: false
            };
        }
        case 'AUTHENTICATION_LOGGED_OUT': {
            return {
                ...state,
                loading: false,
                authenticated: false,
                user: null
            };
        }
        default: {
            return state;
        }
    }
};
