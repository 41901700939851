// @flow
import moment from 'moment-timezone';
import i18n from 'i18next';

import { setMeta, omitMeta } from './meta';
import { getMsFromSeconds } from '../utils';

import type {
    CityPassengers,
    DriverTripItem,
    Order,
    CallStatus,
    Stop,
    Phone
} from '../api/backend-types';
import type { NormalizedStop, OrderUpdate, TimeParams } from '../types/stops';
import type { ID } from '../types/common';
import type { Thunk } from '../types/actions';
import type { MetaAction } from './meta';

export const types = {
    FULL_PAGE_LOADED: 'STOPS/FULL_PAGE_LOADED',
    STOP_EDIT: 'STOPS/STOP_EDIT',
    STOP_SET_TIME_PARAMS: 'STOPS/STOP_SET_TIME_PARAMS',
    ORDER_PICK: 'STOPS/ORDER_PICK',
    ORDER_EDIT: 'STOPS/ORDER_EDIT',
    ORDER_QUEUE_EDIT: 'STOPS/ORDER_QUEUE_EDIT',
    ORDER_QUEUE_DROP: 'STOPS/ORDER_QUEUE_DROP',
    CITY_LOADED: 'STOPS/CITY_LOADED',
    META_SET: 'STOPS/META_SET'
};

export type StopsAction =
    | {
          type: 'STOPS/FULL_PAGE_LOADED',
          payload: { cities: CityPassengers[], trip: DriverTripItem }
      }
    | {
          type: 'STOPS/STOP_EDIT',
          payload: { stopId: ID<'stop'>, data: $Shape<NormalizedStop> }
      }
    | { type: 'STOPS/ORDER_PICK', payload: ?ID<'order'> }
    | {
          type: 'STOPS/ORDER_EDIT',
          payload: { id: ID<'order'>, order: $Shape<Order> }
      }
    | { type: 'STOPS/CITY_LOADED', payload: CityPassengers[] }
    | {
          type: 'STOPS/ORDER_QUEUE_EDIT',
          payload: { id: ID<'order'>, status: OrderUpdate }
      }
    | { type: 'STOPS/ORDER_QUEUE_DROP' }
    | { type: 'STOPS/STOP_SET_TIME_PARAMS', payload: TimeParams };

export const metas = {
    CITIES: 'cities',
    ORDERS: 'orders',
    SHELL: 'shell',
    STOPS: 'stops'
};

export type StopsMeta = {
    loading: boolean
};

function makeStopsMeta(meta: $Shape<StopsMeta> = {}): StopsMeta {
    return {
        loading: false,
        ...meta
    };
}

const setMetaFor = (meta: $Values<typeof metas>) => (value: StopsMeta) =>
    setMeta(meta, value);

function resolveOnSuccess<T: { status: any }>(response: T) {
    if (response.status !== 'success') {
        return Promise.reject(response);
    }

    return response;
}

function rejectWithMessage(message: { method: string, text: string }) {
    return err => Promise.reject(message);
}

const makeError = (method: string) => (text: string) => ({ method, text });

const createFullPageLoadedAction = (payload: {
    cities: CityPassengers[],
    trip: DriverTripItem
}): StopsAction => ({
    type: types.FULL_PAGE_LOADED,
    payload
});

export function editStop(payload: {
    stopId: ID<'stop'>,
    data: $Shape<NormalizedStop>
}): StopsAction {
    return {
        type: types.STOP_EDIT,
        payload
    };
}

export function orderPick(payload: ?ID<'order'>): StopsAction {
    return {
        type: types.ORDER_PICK,
        payload
    };
}

export function orderEdit(payload: {
    id: ID<'order'>,
    order: $Shape<Order>
}): StopsAction {
    return {
        type: types.ORDER_EDIT,
        payload
    };
}

export function citiesLoad(payload: CityPassengers[]): StopsAction {
    return {
        type: types.CITY_LOADED,
        payload
    };
}

export function setOrderUpdateStatus(id: ID<'order'>, status: OrderUpdate) {
    return {
        type: types.ORDER_QUEUE_EDIT,
        payload: {
            id,
            status
        }
    };
}

export function dropQueue() {
    return {
        type: types.ORDER_QUEUE_DROP
    };
}

export const getFullPage = (params: {
    tripId: ID<'trip'>
}): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
): Promise<mixed> => {
    const getTripInfo = () => api['GET /driver/trip/{tripID}/info'](params);
    const getCities = () => api['GET /driver/trip/{tripID}/passengers'](params);
    const error = makeError('getFullPage');
    const setMeta = setMetaFor(metas.CITIES);

    dispatch(setMeta(makeStopsMeta({ loading: true })));
    return Promise.all([getTripInfo(), getCities()])
        .then(([trip, cities]) => {
            dispatch(createFullPageLoadedAction({ trip, cities }));
            dispatch(setMeta(makeStopsMeta()));
        })
        .catch(() => {
            dispatch(setMeta(makeStopsMeta()));

            return Promise.reject();
        })
        .catch(rejectWithMessage(error(`${i18n.t('stopsRideError')}`)));
};

export const getCities = (params: {
    tripId: ID<'trip'>
}): Thunk<StopsAction | MetaAction<StopsMeta>> => (dispatch, _, { api }) => {
    const error = makeError('getCities');
    return api['GET /driver/trip/{tripID}/passengers'](params)
        .then(cities => {
            dispatch(citiesLoad(cities));
        })
        .catch(rejectWithMessage(error(`${i18n.t('stopsLoadError')}`)));
};

export const bindDriver = (params: {
    tripId: ID<'trip'>
}): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const error = makeError('bindDriver');
    return api['GET /driver/trip/{tripID}/bind'](params).catch(
        rejectWithMessage(error(`${i18n.t('toastDriverBindSuccess')}`))
    );
};
export const changePassengerStatus = (
    id: ID<'order'>,
    status: 'check' | 'uncheck'
): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const { stops } = getState();
    const originalPassenger = stops.ordersMap[id];
    const statuseCodeMap = {
        check: 4,
        uncheck: 0
    };
    if (!originalPassenger) {
        return;
    }
    const newStatus = statuseCodeMap[status];

    dispatch(orderEdit({ id, order: { status: newStatus } }));
    dispatch(setOrderUpdateStatus(id, 'pending'));

    return api['GET /driver/passenger/{orderID}/status/{statusCode}']({
        orderId: id,
        statusCode: newStatus
    })
        .then(resolveOnSuccess)
        .then(response => {
            dispatch(setOrderUpdateStatus(id, 'succeed'));
            return response;
        })
        .catch(err => {
            dispatch(setOrderUpdateStatus(id, 'failed'));
            dispatch(
                orderEdit({
                    id,
                    order: {
                        status: originalPassenger.status
                    }
                })
            );
        });
};

export const changeCallStatus = (
    id: ID<'order'>,
    callStatus: CallStatus
): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const { stops } = getState();
    const originalOrder = stops.ordersMap[id];
    const error = makeError('changeCallStatus');

    if (!originalOrder) {
        return Promise.reject(error(`${i18n.t('stopsCallStatusError')}`));
    }

    dispatch(orderEdit({ id, order: { callStatus } }));

    return api['GET /driver/passenger/{orderID}/setcall/{statusCode}']({
        orderId: id,
        statusCode: callStatus
    })
        .then(response => {
            if (response.status !== 'success') {
                return Promise.reject(response);
            }

            return response;
        })
        .catch(err => {
            dispatch(
                orderEdit({
                    id,
                    order: { callStatus: originalOrder.callStatus }
                })
            );

            return Promise.reject(err);
        })
        .catch(rejectWithMessage(error(`${i18n.t('stopsCallStatusError')}`)));
};

export const editComment = (
    id: ID<'order'>,
    comment: string
): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const { stops } = getState();
    const originalOrder = stops.ordersMap[id];
    const tripId = stops.trip ? stops.trip.id : 0;
    const error = makeError('editComment');

    if (!originalOrder) {
        return Promise.reject(error(`${i18n.t('stopsCommentStatusError')}`));
    }

    const newComment = {
        ...originalOrder.comment,
        service: comment
    };

    dispatch(orderEdit({ id, order: { comment: newComment } }));

    return api['POST /driver/passenger/{tripID}/comment/{orderID}']({
        tripId,
        orderId: id,
        comment
    })
        .then(resolveOnSuccess)
        .catch(err => {
            dispatch(
                orderEdit({
                    id,
                    order: { comment: { ...originalOrder.comment } }
                })
            );

            return Promise.reject(err);
        })
        .catch(
            rejectWithMessage(error(`${i18n.t('stopsCommentStatusError')}`))
        );
};

export const editPoint = (params: {
    id: ID<'order'>,
    type: 'from' | 'to',
    point: Stop
}): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const { stops } = getState();
    const originalOrder = stops.ordersMap[params.id];
    const error = makeError('editPoint');

    if (!originalOrder) {
        return Promise.reject(error(`${i18n.t('stopsUpdateStatusError')}`));
    }

    const { id, point, type } = params;
    const newPoint = {
        ...originalOrder[type],
        pointID: point.id,
        point: point.name
    };

    dispatch(
        // flow-ignore type — примитив
        orderEdit({ id, order: { [type]: newPoint } })
    );

    return api['POST /driver/passenger/{orderID}/point/{pointID}']({
        orderId: id,
        pointId: point.id,
        type
    })
        .then(resolveOnSuccess)
        .catch(err => {
            dispatch(
                // flow-ignore type — примитив
                orderEdit({ id, order: { [type]: originalOrder[type] } })
            );
            return Promise.reject(err);
        })
        .catch(rejectWithMessage(error(`${i18n.t('stopsUpdateStatusError')}`)));
};

export const makeATCCall = (
    id: ID<'order'>,
    phone: Phone
): Thunk<StopsAction | MetaAction<StopsMeta>> => (
    dispatch,
    getState,
    { api }
) => {
    const { stops } = getState();
    const originalOrder = stops.ordersMap[id];
    const error = makeError('makeATCCall');

    if (!originalOrder) {
        return Promise.reject(error(`${i18n.t('stopsCallError')}`));
    }

    if (phone.isATCavailable === 0) {
        return Promise.reject(error(`${i18n.t('stopsCallError')}`));
    }

    return api['GET /driver/passenger/{orderID}/call']({ orderId: id })
        .then(response => {
            /**
             * Если произошла ошибка, приходит полный статус
             */
            if (response.status === 'error') {
                dispatch(
                    orderEdit({
                        id,
                        order: {
                            phone: {
                                ...originalOrder.phone,
                                number: response.phone
                            }
                        }
                    })
                );
                return Promise.reject(response);
            }

            return response;
        })
        .catch(rejectWithMessage(error(`${i18n.t('stopsCallError')}`)));
};

export function changeTime(
    timeParams: TimeParams
): Thunk<StopsAction | MetaAction<StopsMeta>> {
    return (dispatch, getState, { api }) => {
        const error = makeError('changeTime');
        const setMeta = setMetaFor(metas.STOPS);
        const { stops } = getState();
        const stop = stops.stopsMap[timeParams.pointId];
        const originStopTime = moment(getMsFromSeconds(stop.time)).utcOffset(
            timeParams.timeDiff
        );
        const currentTime = moment().utcOffset(timeParams.timeDiff);
        const daysDiff = originStopTime.date() - currentTime.date();
        const dayMs = 24 * 60 * 60 * 1000;

        if (currentTime.valueOf() - originStopTime.valueOf() > dayMs) {
            return Promise.reject(error(`${i18n.t('stopsChangeError')}`));
        }

        if (daysDiff === 0 && currentTime.format('HH:mm') > timeParams.time) {
            return Promise.reject(
                error(`${i18n.t('stopsDepartureTimeError')}`)
            );
        }

        dispatch(setMeta({ loading: true }));
        return api['POST /driver/trip/{tripID}/changeTime/{pointID}']({
            tripId: stops.trip ? stops.trip.id : 0,
            pointId: timeParams.pointId,
            time: timeParams.time
        })
            .then(resolveOnSuccess)
            .catch(err => {
                dispatch(setMeta({ loading: false }));

                return Promise.reject(err);
            })
            .catch(
                rejectWithMessage(
                    error(`${i18n.t('stopsDepartureChangeError')}`)
                )
            );
    };
}

export const dropTemporaryState = (): Thunk<
    StopsAction | MetaAction<StopsMeta>
> => dispatch => {
    dispatch(dropQueue());
    Object.keys(metas).forEach(key => dispatch(omitMeta(key)));
};

export function setTimeParams(
    timeParams: TimeParams = { pointId: 0, time: '', timeDiff: 0 }
): StopsAction {
    return {
        type: types.STOP_SET_TIME_PARAMS,
        payload: timeParams
    };
}
