// @flow

import { flatten } from '../utils/flatten';
import { types } from '../actions/stops';

import type {
    OrdersMap,
    StopsMap,
    CitiesList,
    CitiesMap,
    OrderUpdate,
    TimeParams
} from '../types/stops';
import type { ID } from '../types/common';
import type { Reducer } from '../types/reducer';
import type { StopsAction } from '../actions/stops';

import type {
    DriverTripItem,
    Order,
    StopPassengers
} from '../api/backend-types';

export type StopsState = {|
    +citiesMap: CitiesMap,
    +stopsMap: StopsMap,
    +ordersMap: OrdersMap,
    +currentOrderId: ?ID<'order'>,
    +citiesList: CitiesList,
    +trip: ?DriverTripItem,
    +pendingOrders: { [id: ID<'order'>]: OrderUpdate },
    +timeParams: TimeParams
|};

const initialState: StopsState = {
    citiesMap: {},
    stopsMap: {},
    ordersMap: {},
    currentOrderId: null,
    citiesList: [],
    trip: null,
    pendingOrders: {},
    timeParams: { pointId: 0, time: '', timeDiff: 0 }
};

function pickId(key: string = 'id') {
    return function<T>(value: any): T {
        return value[key];
    };
}

function normaliseCities(cities) {
    const stopsMap: StopsMap = {};
    const citiesMap: CitiesMap = {};
    const ordersMap: OrdersMap = {};
    const stops = flatten<StopPassengers>(cities.map(city => city.points));
    const orders = flatten<Order>(stops.map(stop => stop.in || []));

    stops.forEach(stop => {
        // остановка "Не назначено" может быть в каждом городе
        // обрабатываем отдельно
        const unknownStop = stopsMap[Number(stop.id)];
        if (stop.id === '0' && unknownStop) {
            stopsMap[stop.id] = {
                ...unknownStop,
                in: unknownStop.in.concat(
                    (stop.in || []).map(pickId('orderID'))
                ),
                out: unknownStop.out.concat(
                    (stop.out || []).map(pickId('orderID'))
                )
            };

            return;
        }

        stopsMap[stop.id] = {
            ...stop,
            in: (stop.in || []).map(pickId('orderID')),
            out: (stop.out || []).map(pickId('orderID'))
        };
    });

    orders.forEach(order => {
        ordersMap[order.orderID] = order;
    });

    cities.forEach(city => {
        citiesMap[city.id] = {
            ...city,
            points: city.points.map(pickId())
        };
    });

    return {
        stopsMap,
        citiesMap,
        ordersMap
    };
}

export const stopsReducer: Reducer<StopsState, StopsAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.FULL_PAGE_LOADED:
            const { cities, trip } = action.payload;
            const { ordersMap, citiesMap, stopsMap } = normaliseCities(cities);

            return {
                ...state,
                citiesList: cities.map(pickId()),
                stopsMap,
                citiesMap,
                ordersMap,
                trip
            };

        case types.CITY_LOADED: {
            const cities = action.payload;
            const { ordersMap, citiesMap, stopsMap } = normaliseCities(cities);

            return {
                ...state,
                ordersMap,
                citiesMap,
                stopsMap
            };
        }

        case types.ORDER_PICK:
            return {
                ...state,
                currentOrderId: action.payload
            };

        case types.ORDER_EDIT:
            const { id, order: newOrder } = action.payload;
            const order = state.ordersMap[id];

            if (!order) {
                return state;
            }

            return {
                ...state,
                ordersMap: {
                    ...state.ordersMap,
                    [id]: {
                        ...order,
                        ...newOrder
                    }
                }
            };

        case types.STOP_EDIT:
            const { stopId, data } = action.payload;
            const stopToModify = state.stopsMap[stopId];
            if (!stopToModify) {
                return state;
            }

            return {
                ...state,
                stopsMap: {
                    ...state.stopsMap,
                    [stopId]: {
                        ...stopToModify,
                        ...data
                    }
                }
            };

        case types.ORDER_QUEUE_EDIT: {
            const { id, status } = action.payload;
            if (status === 'succeed') {
                const { pendingOrders } = state;
                const newPendingState = Object.keys(pendingOrders).reduce<{
                    [id: ID<'order'>]: OrderUpdate
                }>((acc, key) => {
                    const normalisedKey = Number(key);
                    if (normalisedKey === id) {
                        return acc;
                    }

                    acc[normalisedKey] = pendingOrders[normalisedKey];

                    return acc;
                }, {});

                return {
                    ...state,
                    pendingOrders: newPendingState
                };
            }

            return {
                ...state,
                pendingOrders: {
                    ...state.pendingOrders,
                    [id]: status
                }
            };
        }

        case types.STOP_SET_TIME_PARAMS: {
            return {
                ...state,
                timeParams: action.payload
            };
        }

        default:
            return state;
    }
};
