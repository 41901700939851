// @flow

import { handleResponse, handleRequest } from './interceptor';
import { objToCgi } from './utils';

import type {
    Authorisation,
    AuthorisationV2,
    DriverTripItem,
    TripCity,
    Order,
    CityStops,
    CityPassengers,
    OrderInfo,
    CreateOrder,
    UnixTimestamp,
    UserInfo
} from './backend-types.js';
import type { ID } from '../types/common';

const basePath = window.__API_HOST__ ? `//${window.__API_HOST__}` : '/api';
const defaultOptions: RequestOptions = {
    credentials: 'include',
    mode: 'cors'
};

function fetchApi<T>(url: string, options: RequestOptions = {}): Promise<T> {
    const absoluteUrl = basePath + url;
    const currentLang = localStorage.getItem('i18nextLng');
    const reqOptions = { ...defaultOptions, ...options };
    reqOptions.headers = {
        ...reqOptions.headers,
        'X-Language-Code': currentLang || 'en'
    };
    return handleRequest(absoluteUrl, reqOptions)
        .then(({ url, options }) => handleResponse(fetch(absoluteUrl, options)))
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response);
            }

            // TODO: больше типов запросов
            return (response.json(): Promise<T>);
        });
}

export const noAuthSubPaths = [
    '/token/refresh',
    '/user/sendCode',
    '/user/checkCode'
];

/**
 * Если меняется ручка, то не забудь чекнуть noAuthSubPaths;
 */
export default {
    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'POST /user/login': (params: { login: string, password: string }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: objToCgi({ ...params, isDriver: '1' })
        };
        return fetchApi<Authorisation>('/user/login', options);
    },

    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'POST /token/logout': (params: { refreshToken: string }) => {
        const options: RequestOptions = {
            method: 'POST',
            body: JSON.stringify({ ...params, isDriver: true })
        };

        return fetchApi<{}>('/token/logout', options);
    },

    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'POST /token/refresh': (params: { refreshToken: string }) => {
        const options: RequestOptions = {
            method: 'POST',
            body: JSON.stringify({ ...params })
        };

        return fetchApi<{ refreshToken: string, expiresAt: UnixTimestamp }>(
            '/token/refresh',
            options
        );
    },

    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'GET /token/info': () => fetchApi<Authorisation>('/token/info'),

    'GET /driver/flights/{dayNumber}': (params: { dayNumber: string }) =>
        fetchApi<DriverTripItem[]>(`/driver/flights/${params.dayNumber}`),

    'GET /driver/trip/{tripId}/city': (params: { tripId: number }) =>
        fetchApi<TripCity[]>(`/driver/trip/${params.tripId}/city`),

    'GET /driver/trip/{tripID}/info': (params: { tripId: number }) =>
        fetchApi<DriverTripItem>(`/driver/trip/${params.tripId}/info`),

    'GET /driver/passenger/{orderID}/status/{statusCode}': (params: {
        orderId: ID<'order'>,
        statusCode: $ElementType<Order, 'status'>
    }) =>
        fetchApi<{ status: 'success' } | { status: 'error', code: number }>(
            `/driver/passenger/${params.orderId}/status/${params.statusCode}`
        ),

    'GET /driver/trip/{tripID}/city/{cityID}': (params: {
        tripId: ID<'trip'>,
        cityId: ID<'city'>
    }) =>
        fetchApi<CityStops>(
            `/driver/trip/${params.tripId}/city/${params.cityId}`
        ),

    'GET /driver/passenger/{orderID}/call': (params: {
        orderId: ID<'order'>
    }) =>
        fetchApi<{ status: 'success' } | { status: 'error', phone: string }>(
            `/driver/passenger/${params.orderId}/call`
        ),

    'GET /driver/trip/{tripID}/bind': (params: { tripId: ID<'trip'> }) =>
        fetchApi<{}>(`/driver/trip/${params.tripId}/bind`),

    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'GET /token/logout': () => fetchApi<{ status: 'success' }>('/token/logout'),

    'GET /driver/trip/{tripID}/passengers': (params: { tripId: ID<'trip'> }) =>
        fetchApi<CityPassengers[]>(`/driver/trip/${params.tripId}/passengers`),

    'GET /promocode/getDriver': () =>
        fetchApi<{ code: string }>('/promocode/getDriver'),

    'POST /driver/passenger/{orderID}/point/{pointID}': (params: {
        orderId: ID<'order'>,
        pointId: ID<'stop'>,
        type: 'from' | 'to'
    }) => {
        const options: RequestOptions = {
            method: 'POST'
        };

        return fetchApi<{ status: 'success' }>(
            `/driver/passenger/${params.orderId}/point/${params.pointId}`,
            options
        );
    },

    'POST /driver/passenger/{tripID}/comment/{orderID}': (params: {
        tripId: ID<'trip'>,
        orderId: ID<'order'>,
        comment: string
    }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: objToCgi({ comment: params.comment })
        };

        return fetchApi<{ status: 'success' }>(
            `/driver/passenger/${params.tripId}/comment/${params.orderId}`,
            options
        );
    },

    'GET /driver/passenger/{orderID}/setcall/{statusCode}': (params: {
        orderId: ID<'order'>,
        statusCode: any
    }) =>
        fetchApi<{ status: 'success' }>(
            `/driver/passenger/${params.orderId}/setcall/${params.statusCode}`
        ),

    'GET /driver/trip/{tripID}/order': (params: { tripId: ID<'trip'> }) =>
        fetchApi<OrderInfo>(`/driver/trip/${params.tripId}/order`),

    'POST /driver/passenger/{tripID}/order': (params: {
        tripId: ID<'trip'>,
        order: CreateOrder
    }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params.order)
        };

        return fetchApi<
            | { status: 'success' }
            | { status: 'error', fields: { [key: string]: string } }
        >(`/driver/passenger/${params.tripId}/order`, options);
    },

    'POST /driver/trip/{tripID}/changeTime/{pointID}': (params: {
        tripId: ID<'trip'>,
        pointId: ID<'stop'>,
        time: string
    }) => {
        const options: RequestOptions = {
            method: 'POST'
        };

        return fetchApi<{ status: 'success' }>(
            `/driver/trip/${params.tripId}/changeTime/${params.pointId}?time=${params.time}`,
            options
        );
    },
    'POST /driver/info/{id}': (params: { id: string }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        };

        return fetchApi<UserInfo>(`/driver/info/${params.id}`, options);
    },

    'POST /user/sendCode/{phoneNumber}': (params: { phoneNumber: string }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: objToCgi({ isDriver: '1' })
        };

        return fetchApi<{ status: 'success', wait: number }>(
            `/user/sendCode/${params.phoneNumber}`,
            options
        );
    },

    /**
     * DEPRECATED: удалить после переезда на FB
     */
    'POST /user/checkCode/{phoneNumber}/code/{code}': (params: {
        phoneNumber: string,
        code: string
    }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: objToCgi({ isDriver: '1' })
        };

        return fetchApi<Authorisation>(
            `/user/checkCode/${params.phoneNumber}/code/${params.code}`,
            options
        );
    },

    'POST /user/checkCode/{phoneNumber}/code/{code}?v=2': (params: {
        phoneNumber: string,
        code: string
    }) => {
        const options: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: objToCgi({ isDriver: '1' })
        };

        return fetchApi<AuthorisationV2>(
            `/user/checkCode/${params.phoneNumber}/code/${params.code}?v=2`,
            options
        );
    },

    'POST /token/migrate': () => {
        return fetchApi<AuthorisationV2>(`/token/migrate`, {
            method: 'POST'
        });
    }
};
