// @flow
import { combineReducers } from 'redux';
import { auth } from './auth';
import { stopsReducer } from './stops';
import { metaReducer } from './meta';

import type { AuthState } from './auth';
import type { StopsState } from './stops';
import type { MetaState } from './meta';
import type { Action } from '../actions';
import type { Reducer } from '../types/reducer';

export type RootState = {
    +auth: AuthState,
    +stops: StopsState,
    +meta: MetaState<any>
};

export const rootReducer: Reducer<RootState, Action> = combineReducers({
    auth,
    stops: stopsReducer,
    meta: metaReducer
});
