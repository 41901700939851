// @flow
/* eslint-disable no-redeclare */
import * as React from 'react';
import { connect } from 'react-redux';

import type { RootState } from '../reducers';
import type { Thunk } from '../types/actions';

type ActionMap = <T, A>((...T) => A | Thunk<A>) => (...T) => mixed;

declare function _typedConnect<StateProps: {}, Props>({|
    mapState: (state: RootState, props: $Diff<Props, StateProps>) => StateProps
|}): (
    React.ComponentType<Props>
) => React.ComponentType<$Diff<Props, StateProps>>;

declare function _typedConnect<ActionCreators: {}, Props>({|
    actionCreators: ActionCreators
|}): (
    React.ComponentType<Props>
) => React.ComponentType<$Diff<Props, $ObjMap<ActionCreators, ActionMap>>>;

declare function _typedConnect<StateProps: {}, ActionCreators: {}, Props>({|
    mapState: (
        state: RootState,
        props: $Diff<
            $Diff<Props, StateProps>,
            $ObjMap<ActionCreators, ActionMap>
        >
    ) => StateProps,
    actionCreators: ActionCreators
|}): (
    React.ComponentType<Props>
) => React.ComponentType<
    $Diff<$Diff<Props, StateProps>, $ObjMap<ActionCreators, ActionMap>>
>;

const typedConnect: typeof _typedConnect = ({
    mapState,
    actionCreators
}: any) => {
    return connect(mapState, actionCreators);
};

export default typedConnect;
