// @flow
import * as React from 'react';

export const GlobalContext = React.createContext<TODO>({});

export function GlobalContextProvider(props: { children: React.Node }) {
    // TODO: сделано по фасту, нужно доработать
    const [state, dispatch] = React.useReducer(
        (state, action) => {
            switch (action.type) {
                case 'FORM2_SET_VALUES':
                    return {
                        ...state,
                        form2: {
                            ...state.form2,
                            [action.payload.name]: action.payload.state
                        }
                    };
                default:
                    return state;
            }
        },
        { form2: {} }
    );
    const ctx = React.useMemo(() => ({ state, dispatch }), [state]);

    return (
        <GlobalContext.Provider value={ctx}>
            {props.children}
        </GlobalContext.Provider>
    );
}
