import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const languages = [
    { label: 'ru', name: 'Язык', langName: 'Русский' },
    { label: 'pl', name: 'Język', langName: 'Polski' },
    { label: 'en', name: 'Language', langName: 'English' }
];

const path =
    process.env.NODE_ENV === 'development'
        ? '/locale/{{lng}}/translation.json'
        : '/resources/locale/{{lng}}/translation.json';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: localStorage.getItem('i18nextLng') || 'ru',
        // debug: true,
        detection: {
            order: ['querystring', 'cookie'],
            cache: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: path
        }
    });

export default i18n;
