// @flow
import * as React from 'react';
import { block, mix } from 'nano-bem';
import Typo from '../Typo/Typo';

import style from './Button.module.less';

const b = block('button', style);

type Props = {
    size: 'l' | 'm',
    theme: 'action' | 'normal' | 'link' | 'danger',
    className?: string,
    children: ?React.Node,
    href?: string
};

export default function Button(props: Props) {
    const { children, size, theme, className, href, ...rest } = props;
    const cn = mix(b({ size, theme }), className || '');
    const content = (
        <Typo size="m" weight="medium" className={b('text')}>
            {children}
        </Typo>
    );

    if (href) {
        return (
            <a className={cn} href={href} {...rest}>
                {content}
            </a>
        );
    }

    return (
        <button
            className={cn}
            // TODO: попробовать затянуть типы
            {...rest}
        >
            {content}
        </button>
    );
}
