// @flow
export type MetaAction<T> =
    | { type: 'META/SET_META', payload: { key: string, value: T } }
    | { type: 'META/OMIT_META', payload: string };

export const types = {
    SET_META: 'META/SET_META',
    OMIT_META: 'META/OMIT_META'
};

export function setMeta<T>(key: string, meta: T): MetaAction<T> {
    return {
        type: types.SET_META,
        payload: {
            key,
            value: meta
        }
    };
}

export function omitMeta<T>(key: string): MetaAction<T> {
    return {
        type: types.OMIT_META,
        payload: key
    };
}
