// @flow
import * as React from 'react';
import { block, mix } from 'nano-bem';
import Typo from '../../Typo/Typo';
import User from '../../User/UserContainer';

import './PageHeader.less';

const b = block('header');

export default class PageHeader extends React.PureComponent<{
    title: string,
    subtitle?: string,
    type: 'main' | 'nested'
}> {
    renderNavButtons() {
        return (
            <React.Fragment>
                <User />
            </React.Fragment>
        );
    }

    render() {
        const { title, subtitle } = this.props;
        const titleSize = subtitle ? 'xl' : 'super';

        return (
            <header className={b({ type: this.props.type })}>
                <div className={mix(b('row'), b('title'))}>
                    <Typo
                        className={b('title-tag')}
                        weight="bold"
                        tag="h1"
                        size={titleSize}
                    >
                        {title}
                    </Typo>
                    {this.props.type === 'main' && this.renderNavButtons()}
                </div>
                {subtitle ? (
                    <div className={mix(b('row'), b('subtitle'))}>
                        <Typo size="s">{subtitle}</Typo>
                    </div>
                ) : null}
            </header>
        );
    }
}
