// @flow
import * as React from 'react';
import { block } from 'nano-bem';
import { mix } from '../../utils';

import PageHeader from './Header/PageHeader';
import PageFooter from './Footer/PageFooter';

import './Page.less';

const b = block('page');

class PageBody extends React.PureComponent<{
    tag?: string,
    className?: string,
    children?: ?React.Node
}> {
    render() {
        const Tag = this.props.tag || 'div';
        return (
            <Tag className={mix(b('body'), this.props.className)}>
                {this.props.children}
            </Tag>
        );
    }
}

class PageWrapper extends React.PureComponent<{
    children?: ?React.Node,
    className?: string
}> {
    render() {
        return (
            <section className={mix(b(), this.props.className)}>
                {this.props.children}
            </section>
        );
    }
}

export default {
    Wrapper: PageWrapper,
    Body: PageBody,
    Header: PageHeader,
    Footer: PageFooter
};
