// @flow
import React from 'react';

import type { ToastInsert } from '../types/toast';

export interface ToastContextValue {
    toasts: ToastInsert[];
    pushToast(ToastInsert): void;
    popToast(): void;
    dropToasts(): void;
}

const defaultValue: ToastContextValue = {
    toasts: [],
    pushToast: () => {
        console.error('ToastContextValue: pushToast method impl is not set');
    },
    popToast: () => {
        console.error('ToastContextValue: popToast method impl is not set');
    },
    dropToasts: () => {
        console.error('ToastContextValue: dropToasts method impl is not set');
    }
};

export const ToastContext = React.createContext<ToastContextValue>(
    defaultValue
);
