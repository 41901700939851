// @flow
import * as React from 'react';

import { ToastContext, ToastContextValue } from '../../context/toast';

import type { ToastInsert } from '../../types/toast';

type Props = {
    children?: ?React.Node
};

export function ToastsProvider(props: Props) {
    const [toasts, setToasts] = React.useState<ToastInsert[]>([]);

    const pushToast = React.useCallback(
        (insert: ToastInsert) => {
            const pruned = toasts.filter(
                toast => toast.reason !== insert.reason
            );
            setToasts([...pruned, insert]);
        },
        [toasts]
    );

    const popToast = React.useCallback(() => {
        setToasts(toasts.slice(1));
    }, [toasts]);

    const dropToasts = React.useCallback(() => {
        setToasts([]);
    }, []);

    const value = React.useMemo<ToastContextValue>(
        () => ({
            popToast,
            pushToast,
            toasts,
            dropToasts
        }),
        [toasts, pushToast, popToast, dropToasts]
    );

    return (
        <ToastContext.Provider value={value}>
            {props.children}
        </ToastContext.Provider>
    );
}
