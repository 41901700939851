// @flow

type NestedArray<T> = Array<T | NestedArray<T>>;

export function flatten<T>(arr: any): T[] {
    return arr.reduce((acc, item) => {
        if (Array.isArray(item)) {
            return [...acc, ...flatten(item)];
        }
        return [...acc, item];
    }, []);
}
