// @flow
import * as React from 'react';
import { block } from 'nano-bem';

import Typo from '../Typo/Typo';

import type { ToastInsert } from '../../types/toast';

import './Toast.less';

const b = block('toast');

export default function Toast(props: ToastInsert) {
    const { text, actionText, onActionClick } = props;

    return (
        <div className={b()}>
            <p className={b('message')}>
                <Typo size="s">{text}</Typo>
            </p>
            {actionText ? (
                <button
                    className={b('action')}
                    type="button"
                    onClick={onActionClick}
                >
                    <Typo size="s" weight="medium">
                        {actionText}
                    </Typo>
                </button>
            ) : null}
        </div>
    );
}
