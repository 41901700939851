// @flow
const safeStorage = <T: Object>(storage: Storage, key: string, val: T): ?T => {
    try {
        if (val) {
            storage.setItem(key, JSON.stringify(val));
        } else {
            const item = storage.getItem(key);
            return JSON.parse(item || '');
        }
    } catch (e) {}
};

export const safeSessionStorage = <T>(key: string, val?: T): ?T => {
    return safeStorage(window.sessionStorage, key, val);
};

export const safeLocalStorage = <T>(key: string, val?: T): ?T => {
    return safeStorage(window.localStorage, key, val);
};

export const mix = (...classNames: Array<?string>): string => {
    return classNames
        .filter(Boolean)
        .join(' ')
        .trim();
};

// titles = ['рубль', 'рубля', 'рублей'] (one, two, five)
export const pluralize = (titles: string[]) => (num: number) => {
    let remainder = Math.floor(Math.abs(num)) % 100;
    if (remainder > 10 && remainder < 20) {
        return titles[2];
    }

    remainder = remainder % 10;
    if (remainder === 1) {
        return titles[0];
    } else if (remainder >= 2 && remainder <= 4) {
        return titles[1];
    }

    return titles[2];
};

export function isNil<T>(value: T): boolean {
    return value === null || typeof value === 'undefined';
}

export function moveRedundantSpaces(str: string) {
    return str.replace(/\s{2,}/, ' ');
}

export { getMsFromSeconds } from './time';
