// @flow
import * as React from 'react';
import { Redirect } from '@reach/router';

import { getPathByName } from '../../router';
import { ToastsProvider } from '../Toast/ToastsProvider';

import type { RouterProps } from '../../types/router';

import './App.less';

export default function App(props: { children: React.Node } & RouterProps) {
    return (
        <React.Fragment>
            {props.location.pathname === '/main' && (
                <Redirect to={getPathByName('feed')} noThrow />
            )}
            <ToastsProvider>{props.children}</ToastsProvider>
        </React.Fragment>
    );
}
