// @flow
import moment from 'moment-timezone';

import type { UnixTimestamp } from '../api/backend-types';

export function getMsFromSeconds(seconds: UnixTimestamp) {
    return seconds * 1000;
}

export function getLocalTime(seconds: UnixTimestamp, utcOffset: number) {
    const ms = getMsFromSeconds(seconds);
    const currentLang = localStorage.getItem('i18nextLng') || 'en';
    moment.locale(currentLang);

    return moment(ms)
        .locale(currentLang)
        .utcOffset(utcOffset);
}

export function now(timeDiff?: number) {
    const currentLang = localStorage.getItem('i18nextLng') || 'en';
    moment.locale(currentLang);

    if (typeof timeDiff !== 'undefined' && timeDiff !== null) {
        return moment().utcOffset(timeDiff);
    }

    return moment();
}
