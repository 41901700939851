// @flow
import React, { Component } from 'react';
import { block } from 'nano-bem';

import type { Node } from 'react';
import Typo from '../Typo/Typo';

import './Banner.less';

const b = block('banner');

export default class Banner extends Component<{
    renderPicture?: () => Node,
    renderAction?: () => Node,
    title: string,
    description: string
}> {
    render() {
        return (
            <div className={b()}>
                <div className={b('content')}>
                    <div className={b('picture')}>
                        {this.props.renderPicture && this.props.renderPicture()}
                    </div>
                    <Typo
                        className={b('title')}
                        tag="h1"
                        size="l"
                        weight="bold"
                    >
                        {this.props.title}
                    </Typo>
                    <Typo className={b('description')} tag="p" size="s">
                        {this.props.description}
                    </Typo>
                </div>
                <div className={b('action-wrapper')}>
                    {this.props.renderAction && this.props.renderAction()}
                </div>
            </div>
        );
    }
}
