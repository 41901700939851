// @flow
import { types } from '../actions/meta';
import type { MetaAction } from '../actions/meta';
export type MetaState<T> = {
    +[key: string]: T
};

const initialState = {};

export function metaReducer(
    state: MetaState<mixed> = initialState,
    action: MetaAction<mixed>
) {
    switch (action.type) {
        case types.SET_META:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        case types.OMIT_META:
            const newMeta = {};
            Object.keys(state).forEach(key => {
                if (key === action.payload) {
                    return;
                }

                newMeta[key] = state[key];
            });

            return newMeta;
        default:
            return state;
    }
}
