// @flow
import * as React from 'react';
import { block } from 'nano-bem';

import './Typo.less';

const b = block('typo');

export default class Typo extends React.PureComponent<{
    size: 'super' | 'xl' | 'l' | 'm' | 's' | 'xs',
    tag: string,
    weight?: 'bold' | 'medium',
    children?: ?React.Node,
    className?: string
}> {
    static defaultProps = {
        size: 'm',
        tag: 'span'
    };

    render() {
        const { tag: Tag, size, weight, children, className = '' } = this.props;
        return (
            <Tag className={`${b({ text: size, weight })} ${className}`}>
                {children}
            </Tag>
        );
    }
}
