// @flow
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.array.find-index';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as moment from 'moment';
import { init as sentryInit } from '@sentry/react';
import firebase from 'firebase/app';
import 'firebase/auth';

// Глобальные полифилы
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import 'moment/locale/ru';
import 'moment/locale/en-gb';
import 'moment/locale/pl';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { GlobalContextProvider } from './context/global/global';
import getStore from './store';
import Shell from './components/Shell/Shell';
import { Auth } from './api/utils/Auth';
import i18n from './i18n';

sentryInit({
    dsn: 'https://bf7b2d6eb6f74d48a3570f88c0eca417@sentry.atlasbus.app/4'
});

const currentLang = localStorage.getItem('i18nextLng') || 'en';

moment.locale(currentLang);

firebase.initializeApp(window.__FB_CONFIG__);
const authProvider = firebase.auth();

const auth = new Auth({ authProvider });
const store = getStore(auth);

i18n.on('loaded', () => {
    ReactDOM.render(
        <Provider store={store}>
            <ErrorBoundary>
                <GlobalContextProvider>
                    <React.Suspense>
                        <Shell auth={auth} />
                    </React.Suspense>
                </GlobalContextProvider>
            </ErrorBoundary>
        </Provider>,
        (document.getElementById('root'): any)
    );
});
