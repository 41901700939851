// @flow
import * as React from 'react';
import { block } from 'nano-bem';

const b = block('toast-item');

export default class ToastItem extends React.PureComponent<{|
    timeout: ?number,
    onTimeout: () => mixed,
    children?: ?React.Node
|}> {
    timeoutId: ?TimeoutID;
    componentDidMount() {
        if (this.props.timeout) {
            this.timeoutId = setTimeout(
                this.props.onTimeout,
                this.props.timeout
            );
        }
    }
    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        return <li className={b()}>{this.props.children}</li>;
    }
}
