// @flow
import * as React from 'react';

import { ToastContext } from '../../../context/toast';
import { withContext } from '../../../context';
import Toast from '../../Toast/Toast';
import ToastItem from './ToastItem';
import { b } from './PageFooter.cn';

import type { ToastContextValue } from '../../../context/toast';
import type { ToastInsert } from '../../../types/toast';

import './PageFooter.less';

const TOAST_VISIBILITY_DURATION = 3500; //ms

type Props = {
    children?: ?React.Node
};

type State = {
    toast: ?ToastInsert
};

class PageFooter extends React.PureComponent<ToastContextValue & Props, State> {
    static getDerivedStateFromProps(props: ToastContextValue & Props, state) {
        return {
            toast: props.toasts[0]
        };
    }
    state = {
        toast: null
    };

    renderToast() {
        const props = this.props;
        const toast = this.state.toast;

        if (!toast) {
            return null;
        }

        const onActionClick = () => {
            if (toast.onActionClick) {
                toast.onActionClick();
            }

            props.popToast();
        };

        return (
            <ToastItem
                timeout={toast.onActionClick ? null : TOAST_VISIBILITY_DURATION}
                onTimeout={props.popToast}
            >
                <Toast
                    reason={toast.reason}
                    text={toast.text}
                    actionText={toast.actionText}
                    onActionClick={onActionClick}
                />
            </ToastItem>
        );
    }
    render() {
        return (
            <footer className={b({ empty: true })}>
                <div className={b('wrapper')}>
                    <ul className={b('toasts')}>{this.renderToast()}</ul>
                    {this.props.children}
                </div>
            </footer>
        );
    }
}

export default withContext(ToastContext)<Props>(PageFooter);
