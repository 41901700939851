// @flow
import * as React from 'react';
import { navigate } from '@reach/router';
import { captureException } from '@sentry/react';

import { getPathByName } from '../../router';
import Spin from '../Spin/Spin';

import type { IAuth } from '../../api/utils/Auth';
import type { UserState } from '../../reducers/auth';
export const AuthChecker = (props: {
    auth: IAuth,
    onAlreadySignedIn: UserState => mixed,
    excludePaths: Array<string>,
    children: React.Node
}) => {
    const { auth, onAlreadySignedIn, excludePaths } = props;
    const [checked, setChecked] = React.useState(false);

    React.useEffect(() => {
        if (checked) {
            return;
        }
        // Возможно пользователь уже прошел аутентификацию ранее
        // и нам надо обновить клиентское состояние
        (async () => {
            try {
                if (excludePaths.includes(window.location.pathname)) {
                    setChecked(true);
                    return;
                }

                const user = await auth.checkIfSignedIn();

                setChecked(true);
                if (user && user.id && user.phone) {
                    onAlreadySignedIn(user);
                    if (window.location.pathname === '/') {
                        await navigate(getPathByName('root'), {
                            state: {},
                            replace: true
                        });
                    }
                } else {
                    throw new Error('Not Authorized.');
                }
            } catch (err) {
                captureException(err);
                setChecked(true);
                // Если не пользователь не авторизован, то он проходит авторизацию
                await navigate(getPathByName('language'));
            }
        })();
    }, [auth, onAlreadySignedIn, checked, excludePaths]);

    return checked ? props.children : <Spin size="l" align="center" />;
};
