// @flow
import React from 'react';
import { Router } from '@reach/router';

import App from '../components/App/App';
import Spin from '../components/Spin/Spin';
import { useTranslation } from 'react-i18next';

import type { Routes, ParamsOf, RouteTreeNode } from '../types/router';

const Support = React.lazy(() => import('../screens/Support/Support'));
const FeedContainer = React.lazy(() => import('../screens/Feed/FeedContainer'));
const StopsContainer = React.lazy(() =>
    import('../screens/Stops/StopsContainer')
);
const ProfileContainer = React.lazy(() =>
    import('../screens/Profile/ProfileContainer')
);
const TermsContainer = React.lazy(() =>
    import('../screens/Terms/TermsContainer')
);
const Order = React.lazy(() => import('../screens/Order/Order'));
const Auth2 = React.lazy(() => import('../screens/Auth2/Auth2'));
const Language = React.lazy(() => import('../screens/Language/Language'));

const BASE_URL = window.__BASE_URL__ || '/';
// flow-ignore
const routesByName: Routes = {
    root: {
        name: 'root',
        path: `/main`,
        component: App
    },
    feed: {
        name: 'feed',
        path: '/feed',
        buildPath: () => '/main/feed',
        component: FeedContainer
    },
    stops: {
        name: 'stops',
        path: '/trip/:tripId',
        buildPath: (params: ParamsOf<'stops'>) => `/main/trip/${params.tripId}`,
        component: StopsContainer
    },
    profile: {
        name: 'profile',
        path: '/profile',
        buildPath: () => '/main/profile',
        component: ProfileContainer
    },
    support: {
        name: 'support',
        path: '/support',
        buildPath: () => '/main/support',
        component: Support
    },
    createOrder: {
        name: 'create-order',
        path: '/trip/:tripId/order/create',
        buildPath: ({ tripId, fromId }: ParamsOf<'create-order'>) =>
            `/main/trip/${tripId}/order/create${
                fromId ? '?fromId=' + fromId : ''
            }`,
        component: Order
    },
    auth2: {
        name: 'auth2',
        path: '/auth2',
        component: Auth2
    },
    terms: {
        name: 'terms',
        path: '/terms',
        component: TermsContainer
    },
    language: {
        name: 'language',
        path: '/language',
        component: Language
    }
};

const tree = [
    {
        ...routesByName['terms']
    },
    {
        ...routesByName['language']
    },
    {
        ...routesByName['auth2']
    },
    {
        ...routesByName['root'],
        children: [
            {
                ...routesByName['feed'],
                children: []
            },
            {
                ...routesByName['stops'],
                children: []
            },
            {
                ...routesByName['profile'],
                children: []
            },
            {
                ...routesByName['support'],
                children: []
            },
            {
                ...routesByName['createOrder'],
                children: []
            }
        ]
    }
];

const renderRoutes = (tree: RouteTreeNode<any, any>[]) =>
    tree.map((item, idx) => {
        const { component: Component, path } = item;

        return (
            <Component className="router" path={path} key={idx}>
                {item.children && renderRoutes(item.children)}
            </Component>
        );
    });

export function getPathByName(name: string, params: ParamsOf<TODO>): string {
    const { path, buildPath } = routesByName[name];
    return buildPath ? buildPath(params) : path;
}

export default () => {
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        if (document && i18n.language) document.title = t('title');
    }, [i18n.language, t]);

    return (
        <React.Suspense fallback={<Spin size="l" align="center" />}>
            <Router className="router" basepath={BASE_URL}>
                {renderRoutes(tree)}
            </Router>
        </React.Suspense>
    );
};
