// @flow

export const codes = {
    // Авторизационные коды
    AUTH_REQUIRED: 1004,
    ACCESS_TOKEN_EXPIRED: 1001,
    NOT_ENOUGH_AUTHORITY: 30,

    // Общие коды ответов
    REQUEST_ERROR: 400
};
