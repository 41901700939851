// @flow
import * as React from 'react';
import Error from '../Error/Error';
import Button from '../Button/Button';
import { ErrorBoundary as SentryErrorBoudary } from '@sentry/react';
import i18n from "i18next";

type Props = {
    children: React.Node
};

class ErrorBoundary extends React.Component<Props> {
    renderAction = () => {
        return (
            <Button
                size="m"
                theme="normal"
                onClick={() => window.location.reload()}
            >
                {i18n.t('refreshPage')}
            </Button>
        );
    };

    render() {
        return (
            <SentryErrorBoudary
                fallback={
                    <Error
                        title={i18n.t('somethingWentWrong')}
                        description={i18n.t('somethingWentWrongDesc')}
                        renderAction={this.renderAction}
                    />
                }
            >
                {this.props.children}
            </SentryErrorBoudary>
        );
    }
}

export default ErrorBoundary;
