// @flow
import { captureException } from '@sentry/react';

import api from '..';

import type { UserState } from '../../reducers/auth';
import typeof {
    Error as FBAuthError,
    FirebaseUserCredential
} from 'firebase/auth';
import type { Auth as FBAuth } from 'firebase/auth';

type Token = string;

export interface ICredentials {
    phone: string;
    code: string;
}

export interface IAuth {
    getToken(): Promise<Token>;
    signIn(params: ICredentials): Promise<UserState>;
    signOut(): Promise<void>;
    checkIfSignedIn(): Promise<UserState>;
    /**
     * Временный метод, чтобы не обрабатывать миграцию как обычный URL.
     */
    isMigrationResponse(response: Response): boolean;
}

export class Auth implements IAuth {
    authProvider: FBAuth;

    constructor(params: { authProvider: FBAuth }) {
        this.authProvider = params.authProvider;
    }

    getToken() {
        if (this.authProvider.currentUser) {
            return this.authProvider.currentUser.getIdToken();
        }

        return Promise.reject();
    }

    signOut() {
        return this.authProvider.signOut();
    }

    signIn(params: ICredentials) {
        return this.getBackendToken(params).then(token =>
            this.exchangeToken(token)
        );
    }

    checkIfSignedIn() {
        if (this.authProvider.currentUser) {
            return Promise.resolve(
                this.formUserInfo(this.authProvider.currentUser)
            );
        }

        const authProviderPromise = new Promise<UserState>(
            (resolve, reject) => {
                const unsubscribe = this.authProvider.onAuthStateChanged(
                    user => {
                        if (user) {
                            resolve(this.formUserInfo(user));
                        } else {
                            reject()
                        }

                        unsubscribe();
                    },
                    (error: FBAuthError) => {
                        captureException(error);
                    }
                );
            }
        );

        return api['POST /token/migrate']()
            .then(data => {
                if (data && data.token) {
                    return this.exchangeToken(data.token);
                }

                return authProviderPromise;
            })
            .catch(e => {
                return authProviderPromise;
            });
    }

    isMigrationResponse(response: Response) {
        return response.url.indexOf('/token/migrate') > -1;
    }

    /** Приватные методы **/
    getBackendToken(credentials: ICredentials): Promise<Token> {
        return api['POST /user/checkCode/{phoneNumber}/code/{code}?v=2']({
            phoneNumber: credentials.phone,
            code: credentials.code
        }).then(data => data.token);
    }

    async formUserInfo(
        user: $PropertyType<FirebaseUserCredential, 'user'>
    ): Promise<UserState>  {

        try {
            const data = await api['POST /driver/info/{id}']({
                id: user.uid,
            });

            return {
                id: user.uid,
                phone: data.phone || '',
                name: data.name,
                surname: data.surname
            }
        }
        catch(e) {
            const [surname = '', name = ''] = (user.displayName || '').split(' ');

            return {
                id: user.uid,
                phone: user.phoneNumber || '',
                name,
                surname
            };
        }
    }

    exchangeToken(token: Token): Promise<UserState> {
        return this.authProvider
            .signInWithCustomToken(token)
            .then(({ user }) => this.formUserInfo(user));
    }
}
