// @flow
import * as React from 'react';
import { block } from 'nano-bem';

import Page from '../Page/Page';
import Banner from '../Banner/Banner';

import './Error.less';

const b = block('error');

export default class Error extends React.PureComponent<{
    title: string,
    description: string,
    onGoBack?: ?() => mixed,
    renderPicture?: () => React.Node,
    renderAction?: () => React.Node
}> {
    render() {
        return (
            <Page.Wrapper>
                <div className={b()}>
                    <div className={b('wrapper')}>
                        <Banner
                            renderPicture={this.props.renderPicture}
                            renderAction={this.props.renderAction}
                            title={this.props.title}
                            description={this.props.description}
                        />
                    </div>
                </div>
            </Page.Wrapper>
        );
    }
}
