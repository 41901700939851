// @flow
import * as React from 'react';
import { Location } from '@reach/router';

import User from './User';
import { getPathByName } from '../../router';
import typedConnect from '../../utils/typedConnect';

import type { Props } from './User';
import type { RouterProps } from '../../types/router';

const UserContainer = (props: Props & RouterProps) => {
    const navigate = props.navigate;
    const onClick = React.useCallback(() => {
        navigate(getPathByName('profile'));
    }, [navigate]);

    return <User name={props.name} surname={props.surname} onClick={onClick} />;
};

const Presenter = (props: Props) => (
    <Location>
        {locationProps => <UserContainer {...props} {...locationProps} />}
    </Location>
);

const mapState = (state: *) => {
    return {
        ...state.auth.user
    };
};

export default typedConnect({ mapState })(Presenter);
