// @flow

import { block, mix } from 'nano-bem';
import * as React from 'react';

import { ReactComponent as SpinIcon } from './images/spin.svg';
import './Spin.less';

const b = block('spin');

type IconSize = 'l' | 'm' | 's';

export interface Props {
    className?: string;
    size?: IconSize;
    align?: 'center';
}

export default (props: Props) => {
    const size: IconSize = props.size || 'm';
    const cn = mix(b({ size, align: props.align }), props.className || '');

    return (
        <div className={cn}>
            <SpinIcon className={b('icon')} />
        </div>
    );
};
