// @flow
import { configureScope } from '@sentry/react';

import type { Thunk } from '../types/actions';
import type { AuthError, UserState } from '../reducers/auth';
import type { ICredentials } from '../api/utils/Auth';

export type AuthAction =
    | { type: 'AUTHENTICATION' }
    | { type: 'AUTHENTICATION_FAILED', error?: AuthError }
    | { type: 'AUTHENTICATION_SUCCESS', data: UserState }
    | { type: 'AUTHENTICATION_LOGGED_OUT' };

const AUTHENTICATION: AuthAction = {
    type: 'AUTHENTICATION'
};

const AUTHENTICATION_LOGGED_OUT: AuthAction = {
    type: 'AUTHENTICATION_LOGGED_OUT'
};

export const authSuccess = (data: *): AuthAction => {
    configureScope(scope => scope.setUser(data));

    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'userKnown',
                userId: data.phone
            })
        );
    }

    return {
        type: 'AUTHENTICATION_SUCCESS',
        data
    };
};

export const authenticate = (credentials: ICredentials): Thunk<AuthAction> => (
    dispatch,
    _,
    { auth }
) => {
    return auth.signIn(credentials).then(data => dispatch(authSuccess(data)));
};

export const logOut = (): AuthAction => AUTHENTICATION_LOGGED_OUT;

export const onUserLogOut = (): Thunk<AuthAction> => (
    dispatch,
    getState,
    { api, auth }
) => {
    dispatch(AUTHENTICATION);

    // Вызываем на всякий случай, чтобы убить сарую сессию.
    api['GET /token/logout']();

    return auth.signOut().then(
        () => {
            return dispatch(logOut());
        },
        () => {
            return dispatch(logOut());
        }
    );
};
