// @flow
import * as React from 'react';
import Typo from '../Typo/Typo';

import './User.less';

export type Props = {
    name?: string,
    surname?: string,
    onClick: () => mixed
};

function User(props: Props) {
    const initials = `${props.name ? props.name[0] : ''}${
        props.surname ? props.surname[0] : ''
    }`.toUpperCase();

    return (
        <div className={'user'} onClick={props.onClick}>
            <Typo size="s" weight="bold">
                {initials}
            </Typo>
        </div>
    );
}

export default React.memo<Props>(User);
